*{
	box-sizing: border-box;
}
body {
	background: #fff;
}
.modal__overlay{
	z-index: 10;
}
.inner{
	max-width: 1230px;
	padding: 0 15px;
	margin: 0 auto;
}

.first{
	background: url("/public/images/headerbg.jpg");
	padding-top: 38.25px;
	padding-bottom: 92px;
	background-size: cover;
	@media(max-width: 688px){
		padding-top: 32px;
		padding-bottom: 117px;
	}
}

.logo{
	position: relative;
	z-index: 2;
	svg{
		path:not(:first-child){
			transition: .2s all;
			fill: #fff;
		}
	}
}

.first-top-menu{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	ul{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		transition: .2s all;
		li:last-child{
			margin-left: 124px;
		}
		a{
			font-size: 23px;
			color: #fff;
			text-decoration: none;
		}
	}
	.openmenu{
		display: none;
		position: relative;
		z-index: 2;
		svg{
			path{
				transition: .2s all;
				fill: #fff;
			}
		}
	}
	@media(max-width: 688px){
		ul{
			background: #fff;
			position: absolute;
			width: 100%;
			left: 0px;
			top: -100%;
			padding-top: 132px;
			padding-bottom: 64px;
			li{
				width: 100%;
				margin-left: 0;
				margin-bottom: 50px;
				text-align: center;
				&:last-child{
					margin-left: 0;
					margin-bottom: 0;
				}
				a{
					font-size: 23px;
					color: #000;
				}
			}
		}
		.openmenu{
			display: block
		}
	}
}
.active{
	@media(max-width: 688px){
		.first-top-menu{
			.openmenu{
				svg{
					path{
						fill: #000;
					}
				}
			}
		}
		ul{
			top: 0px;
		}
		.logo{
			svg{
				path:not(:first-child){
					fill: #000;
				}
			}
		}
	}
}

.first-text{
	padding-top: 148.91px;
	&>div{
		max-width: 800px;
		&>p{
			font-weight: normal;
			font-size: 23px;
			line-height: 26px;
			margin-top: 40px;
			color: #fff;
			strong{
				color: #38D090;
				font-weight: 400;
			}
		}
	}
	h2{
		font-weight: 500;
		font-size: 50px;
		color: #fff;
	}
	@media(max-width: 688px){
		padding-top: 118px;
		h2{
			font-size: 25px;
			line-height: 29px;
		}
		&>div{
			&>p{
				font-size: 17px;
				line-height: 20px;
				margin-top: 30px;
			}
		}
	}
}


.first-text-btn{
	margin-top: 70px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	p{
		font-size: 23px;
		line-height: 26px;
		color: #FFFFFF;
		border-bottom: 1px solid #fff;
		margin-right: 99px;
		position: relative;
		&:after{
			position: absolute;
			content: "";
			width: 39px;
			height: 14px;
			background: url("data:image/svg+xml,%3Csvg width='39' height='14' viewBox='0 0 39 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.688767 7.7273L36.552 7.7273L31.4552 12.8241C31.1861 13.093 31.1861 13.5293 31.4552 13.7982C31.7242 14.0673 32.1601 14.0673 32.4292 13.7982L38.627 7.60044C38.8035 7.47571 38.9194 7.27104 38.9194 7.03845C38.9194 7.03492 38.9185 7.03164 38.9184 7.02811C38.9185 7.02057 38.9163 7.01345 38.9162 7.00591C38.913 6.93856 38.8998 6.87429 38.8787 6.81335C38.8663 6.77467 38.8484 6.73978 38.829 6.70334C38.8116 6.6724 38.7923 6.64311 38.7705 6.61533C38.7511 6.58952 38.7411 6.55922 38.7176 6.53573L32.3837 0.201807C32.2492 0.0672668 32.0729 -5.98541e-07 31.8967 -6.13949e-07C31.7204 -6.29358e-07 31.5442 0.0672668 31.4096 0.201807C31.1406 0.470888 31.1406 0.906799 31.4096 1.17588L36.5834 6.34961L0.688767 6.34961C0.308472 6.34961 -7.56521e-05 6.65793 -7.56853e-05 7.03845C-7.57186e-05 7.41897 0.308472 7.7273 0.688767 7.7273Z' fill='white'/%3E%3C/svg%3E%0A");
			top: 5px;
    		right: -69px;
		}
	}
	a{
		font-size: 23px;
		line-height: 26px;
		color: #FFFFFF;
		background: #38D090;
		text-decoration: none;
		padding: 17px 27px;
		transition: .2s all;
		&:hover{
			background: #fff;
			color: #000;
		}
	}
	@media(max-width: 688px){
		margin-top: 30px;
		display: block;
		p{
			font-size: 15px;
			line-height: 17px;
			color: #7A7A7A;
			border-bottom: 0;
			&:after{
				display: none;
			}
		}
		a{
			font-size: 17px;
			line-height: 20px;
			margin-top: 15px;
			display: inline-block;
			padding: 17px 48px;
		}
	}
}

.second{
	font-weight: 400;
	font-size: 23px;
	line-height: 30px;
	text-align: center;
	color: #000000;
	padding-top: 200px;
	padding-bottom: 140px;
	strong{
		font-weight: 500;
	}
	@media(max-width: 688px){
		font-size: 17px;
		line-height: 21px;
		padding-top: 153px;
		padding-bottom: 100px;
	}
}

.second-inner{
	max-width: 790px;
	margin: 0 auto;
	position: relative;
	&:after{
		content: "";
		width: 29px;
		height: 24px;
		background: url("data:image/svg+xml,%3Csvg width='29' height='24' viewBox='0 0 29 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.12598 11.0508C8.00781 11.0508 9.48926 11.6113 10.5703 12.7324C11.6914 13.8135 12.252 15.2949 12.252 17.1768C12.252 19.0586 11.6914 20.5601 10.5703 21.6812C9.48926 22.8022 8.00781 23.3628 6.12598 23.3628C4.24414 23.3628 2.74268 22.8022 1.62158 21.6812C0.540527 20.5601 0 19.0586 0 17.1768C0 14.2939 0.500488 11.7515 1.50146 9.54932C2.54248 7.34717 3.68359 5.54541 4.9248 4.14404C6.40625 2.50244 8.04785 1.12109 9.84961 0L11.0508 1.80176C10.25 2.60254 9.50928 3.46338 8.82861 4.38428C8.26807 5.18506 7.70752 6.146 7.14697 7.26709C6.62646 8.38818 6.28613 9.64941 6.12598 11.0508ZM22.7622 11.0508C24.644 11.0508 26.1255 11.6113 27.2065 12.7324C28.3276 13.8135 28.8882 15.2949 28.8882 17.1768C28.8882 19.0586 28.3276 20.5601 27.2065 21.6812C26.1255 22.8022 24.644 23.3628 22.7622 23.3628C20.8804 23.3628 19.3789 22.8022 18.2578 21.6812C17.1367 20.5601 16.5762 19.0586 16.5762 17.1768C16.5762 14.2939 17.0767 11.7515 18.0776 9.54932C19.1187 7.34717 20.2598 5.54541 21.501 4.14404C22.9824 2.50244 24.624 1.12109 26.4258 0L27.627 1.80176C26.8262 2.60254 26.1055 3.46338 25.4648 4.38428C24.9043 5.18506 24.3438 6.146 23.7832 7.26709C23.2627 8.38818 22.9224 9.64941 22.7622 11.0508Z' fill='%23D0D0D0'/%3E%3C/svg%3E%0A");
		position: absolute;
		top: -64px;
		left: 50%;
		margin-left: -14.5px;
	}
}

.carousel-inner{
	border-top: 1px solid #D0D0D0;
	padding-bottom: 70px;
	h2{
		font-weight: 500;
		font-size: 33px;
		line-height: 38px;
		color: #000000;
		margin-top: 40px;
		margin-bottom: 60px;
	}
	@media(max-width: 688px){
		h2{
			font-size: 17px;
			line-height: 20px;
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
	.splide__pagination{
		display: none;
	}
	.splide__slide {
		a{
			text-decoration: none;
		}
		p{
			font-size: 18px;
			line-height: 21px;
			color: #7A7A7A;
			margin-top: 20px;
		}
		img{
			width: 100%;
		}
		@media(max-width: 688px){
			p{
				font-size: 15px;
				line-height: 17px;
			}
		}
	}

	.splide__arrow{
		width: 69px;
		height: 69px;
		transform: none;
		top: -115px;
		opacity: 1;
		border: 2px solid #000000;
		background: none;
		border-radius: 100%;
		transition: .2s all;
		path{
			transition: .2s all;
		}
		&:hover{
			border: 2px solid #38D090;
			path{
				fill: #38D090;
			}
		}
		@media(max-width: 688px){
			width: 24px;
	    	height: 24px;
	    	border: 0;
	    	top: -54px;
	    	transform: scale(0.8);
	    	&:hover{
	    		border: 0;
	    	}
		}
	}

	.splide__arrow--next{
		right: 0px;
		@media(max-width: 688px){
			right: -6px;
		}
	}

	.splide__arrow--prev{
		right: 102px;
		left: auto;
		@media(max-width: 688px){
			right: 34px;
		}
	}
}




.first1{
	background: #F5F5F5;
	padding-top: 83px;
	padding-bottom: 86px;
	h2{
		color: #000000;
	}
	p{
		color: #000000;
	}
	.first-text{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-top: 0;
		padding-bottom: 0;
		&>div{
			&:first-child{
				flex: 0 0 54%;
				min-width: 645px;
			}
			&:last-child{
				flex: 1;
				text-align: center;
			}
			p{
				color: #000;
			}
		}
	}
	.first-text-btn{
		p{
			border-bottom: 1px solid #000;
		}
		p:after{
			background: url("data:image/svg+xml,%3Csvg width='39' height='14' viewBox='0 0 39 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.688767 7.7273L36.552 7.7273L31.4552 12.8241C31.1861 13.093 31.1861 13.5293 31.4552 13.7982C31.7242 14.0673 32.1601 14.0673 32.4292 13.7982L38.627 7.60044C38.8035 7.47571 38.9194 7.27104 38.9194 7.03845C38.9194 7.03492 38.9185 7.03164 38.9184 7.02811C38.9185 7.02057 38.9163 7.01345 38.9162 7.00591C38.913 6.93856 38.8998 6.87429 38.8787 6.81335C38.8663 6.77467 38.8484 6.73978 38.829 6.70334C38.8116 6.6724 38.7923 6.64311 38.7705 6.61533C38.7511 6.58952 38.7411 6.55922 38.7176 6.53573L32.3837 0.201807C32.2492 0.0672668 32.0729 -5.98541e-07 31.8967 -6.13949e-07C31.7204 -6.29358e-07 31.5442 0.0672668 31.4096 0.201807C31.1406 0.470888 31.1406 0.906799 31.4096 1.17588L36.5834 6.34961L0.688767 6.34961C0.308472 6.34961 -7.56521e-05 6.65793 -7.56853e-05 7.03845C-7.57186e-05 7.41897 0.308472 7.7273 0.688767 7.7273Z' fill='black'/%3E%3C/svg%3E%0A");
		}
	}
	@media(max-width: 932px){
		.first-text{
			&>div{
				&:first-child{
					min-width: unset;
					flex: 0 0 100%;
				}
				&:last-child{
					display: none;
				}
			}
		}
	}
	@media(max-width: 688px){
		padding-top: 100px;
		padding-bottom: 100px;
		div.first-text-btn{
			p{
				color: #7A7A7A;
				border-bottom: 0;
			}
		}
	}
}

.aboutus{
	padding-top: 140px;
	padding-bottom: 140px;
	text-align: center;
	h2{
		font-weight: 500;
		font-size: 50px;
		line-height: 57px;
		color: #000000;
	}
	p{
		font-size: 23px;
		line-height: 30px;
		color: #000000;
		padding-top: 40px;
	}
	.inner{
		max-width: 920px;
		margin: 0 auto;
	}
	@media(max-width: 688px){
		padding-top: 100px;
		padding-bottom: 100px;
		h2{
			font-weight: 500;
			font-size: 25px;
			line-height: 29px;
		}

		p{
			font-size: 17px;
			line-height: 21px;
			text-align: center;
			padding-top: 30px;
		}
	}
}

.footer{
	border-top: 1px solid #D0D0D0;
	padding-top: 60px;
	padding-bottom: 156px;
	@media(max-width: 688px){
		padding-top: 50px;
		padding-bottom: 56px;
	}
}

.footer-inner{
	
	display: flex;
	flex-wrap: wrap;
	&>div{
		flex: 1;
	}

	h3{
		font-weight: 500;
		font-size: 23px;
		line-height: 26px;
		color: #000000;
		margin-bottom: 22px;
	}
	ul{
		li{
			margin-bottom: 20px;
		}
		a{
			font-size: 23px;
			line-height: 26px;
			color: #000000;
			text-decoration: none;
		}
	}

	form{
		&>p{
			display: flex;
			flex-wrap: wrap;
			br{
				display: none;
			}
		}
		.wpcf7-not-valid-tip{
			display: block;
		}
		
		input{
			width: 308px;
			height: 60px;
			background: #F5F5F5;
			border: 0;
			padding: 0 20px;
			font-family: 'TT Commons';
			font-size: 18px;
			line-height: 21px;
			color: rgba(0, 0, 0, 0.5);
		}

		button{
			width: 240px;
			height: 60px;
			background: #38D090;
			font-size: 23px;
			line-height: 27px;
			text-transform: lowercase;
			color: #FFFFFF;
			border: 0;
			font-family: 'TT Commons';
			cursor: pointer;
		}
	}

	@media(max-width: 688px){
		&>div:first-child{
			display: none;
		}
		h3{
			display: none;
		}
		form{
			display: none;
		}
	}
}

.social{
	margin-top: 93px;
	display: flex;
	flex-wrap: wrap;
	a{
		width: 60px;
		height: 60px;
		border: 1px solid #F5F5F5;
		border-radius: 100%;
		transition: .2s all;
		background: #fff;
		margin-right: 40px;
		margin-bottom: 30px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		&:first-child{
			svg{
				path{
					fill: #d6afca;
					transition: .2s all;
				}
			}
		}
		&:last-child{
			margin-right: 0;
		}
		&:hover{
			background: #F5F5F5;
			svg{
				path{
					fill: #38D090;
				}
			}
		}
	}

	@media(max-width: 688px){
		margin-top: 0;
		a{
			width: 40px;
			height: 40px;
			svg{
				width: 18px;
				height: 18px;
			}
		}
	}
	@media(max-width: 389px){
		a{
			&:nth-child(4){
				margin-right: 0;
			}
		}
	}
}


[data-micromodal-trigger]{
	cursor: pointer;
}

.modal{
	font-family: 'TT Commons';
}

.modal__header{
	position: relative;
}
.modal__close{
	cursor: pointer;
	padding: 0;
	font-size: 21px;
	color: #D0D0D0;
	position: absolute;
	top: 0px;
    right: 0px;
    line-height: 17px;
}

.modal__container{
	max-width: 600px;
	border-radius: 0px;
	padding-bottom: 50px;
}

.modal__content{
	margin-top: 0;
	margin-bottom: 0;
	h2{
		font-weight: 500;
		font-size: 23px;
		line-height: 26px;
		color: #000000;
		margin-top: 30px;
		margin-bottom: 30px;
	}

	p{
		margin-top: 30px;
		margin-bottom: 50px;
		font-size: 18px;
		line-height: 24px;
	}

	.first1{
		padding-top: 0;
		padding-bottom: 0;
		background: none;
	}
	.first-text-btn{
		margin-top: 0;
		p{
			font-size: 18px;
			line-height: 21px;
			border-bottom: 0;
			margin-top: 0;
			margin-bottom: 0;
			margin-right: 81px;
			&:after{
				width: 28px;
				height: 10px;
				background-size: cover;
				right: -53px;
			}
		}
		a{
			padding: 14px 25px;
		}
	}
}

.modal-tag{
	font-size: 14px;
    line-height: 16px;
    border: 1px solid #38D090;
    display: inline-block;
    padding: 9px 8px;
}

.modal-imgs{
	display: flex;
	flex-wrap: wrap;
	a{
		width: 30%;
		margin-right: 5%;
		&:nth-child(3n){
			margin-right: 0;
		}
	}
	img{
		width: 100%;
		height: auto;
	}
	@media(max-width: 412px){
		display: none;
	}
}

.micromodal-slide{
	.splide{
		display: none;
	}
	
	@media(max-width: 412px){
		.splide{
			display: block;
		}
	}
	.splide__arrows{
		display: none;
	}
	.splide__pagination__page{
		width: 8px;
		height: 2px;
		background: #C4C4C4;
		border-radius: 2px;
		transition: .2s all;
		&.is-active{
			width: 24px;
			height: 2px;
			background: #38D090;
			transform: scale(1);
		}
	}
	.splide__pagination{
		bottom: -23px;
	}
}
.splide__slide{
	.glightbox{
		display: block;
	}
}
#map1,#map2{
	height: 680px;
	@media(max-width: 600px){
		height: 480px;
	}
}

.mapblock{
	padding-top: 100px;
	.inner{
		position: relative;
	}
}

.map-buttons{
	display: flex;
    flex-wrap: wrap;
    position: absolute;
    right: 87px;
    bottom: 40px;
	div{
		font-weight: normal;
		font-size: 23px;
		line-height: 26px;
		background: #FFFFFF;
		border: 1px solid #000000;
		padding: 14px 29px;
		cursor: pointer;
		&.active{
			background: #000;
			color: #fff;
		}
	}
	@media(max-width: 600px){
		right: 75px;
		bottom: 30px;
		div{
			font-size: 15px;
			line-height: 17px;
			text-align: center;
			padding: 11px 10px;
		}
	}
}

.map{
	display: none;
	&.active{
		display: block;
	}
}

#modal-splide{
	img{
		width: 100%;
	}
}

.carousel-img{
	height: 380px;
}